import { RouteRecordRaw } from 'vue-router'
import { support } from '@/helpers/env'
import { supportRoutes } from '@/supportRoutes'

function allowShortcuts(_routes: RouteRecordRaw[], params: string[]): RouteRecordRaw[] {
  return _routes.map((route) => allowShortcut(route, params))
}

function allowShortcut(route: RouteRecordRaw, params: string[]): RouteRecordRaw {
  if (route.children) {
    route.children = allowShortcuts(route.children, params)
  }
  for (const param of params) {
    if (!route.path.includes(`:${param}`)) {
      continue
    }
    route.path = route.path.replaceAll(`:${param}(\\d+)`, `:${param}`)
    if (!route.meta) {
      route.meta = {}
    }
    if (!route.meta.params) {
      route.meta.params = {}
    }
    if (route.path.includes(`:${param}?`)) {
      route.meta.params[param] = false
      route.path = route.path.replaceAll(`:${param}?`, `:${param}`)
    } else {
      route.meta.params[param] = true
    }
    route.path = route.path.replaceAll(`:${param}`, `:${param}(\\d+)?`)
  }
  return route
}

let routes: RouteRecordRaw[] = [
  {
    path: '/installation',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/',
    name: 'main',
    redirect: { name: support ? 'support' : 'dashboard' },
    meta: { acl: { dashboard: true } },
  },
  {
    path: '/dashboard/:siteId',
    name: 'dashboard',
    component: () => import('@/views/DashboardView.vue'),
    meta: {
      site: true,
      acl: { dashboard: true },
      title: 'Udwi | Tableau de bord',
    },
  },
  {
    path: '/init',
    name: 'init',
    component: () => import('@/views/Init/Intro.vue'),
    props: true,
    meta: {
      dashboard: false,
      acl: { dashboard: false },
    },
  },
  {
    path: '/init/site',
    name: 'init.site',
    component: () => import('@/views/Init/SiteView.vue'),
    props: true,
    meta: { dashboard: false },
  },
  {
    path: '/init/periods',
    name: 'init.periods',
    component: () => import('@/views/Init/Periods.vue'),
    props: true,
    meta: { dashboard: false },
  },
  {
    path: '/init/members',
    name: 'init.members',
    component: () => import('@/views/Init/Members.vue'),
    props: true,
    meta: { dashboard: false },
  },
  {
    path: '/init/kits',
    name: 'init.kits',
    component: () => import('@/views/Kits.vue'),
    props: true,
    meta: { dashboard: false },
  },
  {
    path: '/kits/:siteId',
    name: 'kits',
    component: () => import('@/views/Kits.vue'),
    props: true,
  },
  {
    path: '/kits/enedis/:siteId/:state?',
    name: 'kits.enedis',
    component: () => import('@/views/Kits/Enedis/Electricity.vue'),
    props: true,
  },
  {
    path: '/kits/grdf/:siteId/:state?',
    name: 'kits.grdf',
    component: () => import('@/views/Kits/GRDF/Gas.vue'),
    props: true,
  },
  {
    path: '/kits/fludia/:siteId',
    name: 'kits.kit-fludia',
    component: () => import('@/views/Kits/Fludia/Gateway.vue'),
    props: true,
  },
  {
    path: '/kits/webdyn/:siteId',
    name: 'kits.kit-lorawan',
    component: () => import('@/views/Kits/WebDyn/Gateway.vue'),
    props: true,
  },
  {
    path: '/kits/webdyn/sensor/:siteId',
    name: 'kits.sensor-lorawan',
    component: () => import('@/views/Kits/WebDyn/Sensor.vue'),
    props: true,
  },
  {
    path: '/kits/webdyn/electricity/:source/:siteId',
    name: 'kits.sensor-lorawan.electricity',
    component: () => import('@/views/Kits/WebDyn/Electricity.vue'),
    props: true,
  },
  {
    path: '/kits/webdyn/comfort/:source/:siteId',
    name: 'kits.sensor-lorawan.comfort',
    component: () => import('@/views/Kits/WebDyn/Comfort.vue'),
    props: true,
  },
  {
    path: '/kits/d2l/:siteId/:sensorId?',
    name: 'kits.ee-smart',
    component: () => import('@/views/Kits/D2L/Electricity.vue'),
    props: true,
  },
  {
    path: '/kits/fludia/elec/:siteId/:sensorId?',
    name: 'kits.sensor-fludia-elec',
    redirect: { name: 'kits.sensor-fludia-electricity' },
  },
  {
    path: '/kits/fludia/th/:siteId/:sensorId?',
    name: 'kits.sensor-fludia-th',
    redirect: { name: 'kits.sensor-fludia-comfort' },
  },
  {
    path: '/kits/webdyn/sensor/:siteId/sensorId?',
    name: 'kits.sensor-picowan',
    redirect: { name: 'kits.sensor-lorawan' },
  },
  {
    path: '/kits/fludia/electricity/:siteId/:sensorId?',
    name: 'kits.sensor-fludia-electricity',
    component: () => import('@/views/Kits/Fludia/Electricity.vue'),
    props: true,
  },
  {
    path: '/kits/fludia/comfort/:siteId/:sensorId?',
    name: 'kits.sensor-fludia-comfort',
    component: () => import('@/views/Kits/Fludia/Comfort.vue'),
    props: true,
  },
  {
    path: '/electricity/consumption/:siteId',
    name: 'electricity.consumption',
    component: () => import('@/views/ConsumptionView.vue'),
    meta: {
      title: 'Évolution conso',
      acl: { dashboard: true },
    },
  },
  {
    path: '/gas/consumption/:siteId',
    name: 'gas.consumption',
    component: () => import('@/views/GasView.vue'),
    meta: {
      title: 'Évolution gaz',
      acl: { dashboard: true },
    },
  },
  {
    path: '/installation/gas/:siteId',
    name: 'installation.gas',
    redirect: { name: 'installation.gas.pce' },
    component: () => import('@/views/Kits/GAS/GasView.vue'),
    meta: {
      title: 'Parcours Gaz',
      acl: { dashboard: true },
    },
    children: [
      {
        path: 'pce',
        name: 'installation.gas.pce',
        component: () => import('@/views/Kits/GAS/GasViewPCE.vue'),
        meta: {
          title: 'Parcours Gaz',
          acl: { dashboard: true },
        },
      },
      {
        path: 'consent',
        name: 'installation.gas.consent',
        component: () => import('@/views/Kits/GAS/GasViewConsent.vue'),
      },
      {
        path: 'confirm',
        name: 'installation.gas.confirm',
        component: () => import('@/views/Kits/GAS/GasViewConfirm.vue'),
      },
    ],
  },
  {
    path: '/electricity/power/:siteId',
    name: 'electricity.power',
    component: () => import('@/views/Power/PowerView.vue'),
    meta: {
      title: 'Diagnostic journalier',
      acl: { dashboard: true },
    },
  },
  {
    path: '/comfort/temperature/:siteId/:sourceId',
    name: 'comfort.temperature',
    component: () => import('@/views/Comfort/ComfortTemperatureView.vue'),
    meta: {
      title: 'Température',
      acl: { dashboard: true },
    },
  },
  {
    path: '/comfort/humidity/:siteId',
    name: 'comfort.humidity',
    component: () => import('@/views/Comfort/ComfortHumidityView.vue'),
    meta: {
      title: "Taux d'humidité",
      acl: { dashboard: true },
    },
  },
  {
    path: '/comfort/advanced/:siteId/',
    name: 'comfort.advanced',
    component: () => import('@/views/ComfortView.vue'),
    meta: {
      title: 'Confort',
      acl: { dashboard: true },
    },
  },
  {
    path: '/air_conditioning/:siteId',
    name: 'air.conditioning',
    component: () => import('@/views/AirConditioning/AirConditioningView.vue'),
    meta: {
      title: 'Climatisation',
      acl: { dashboard: true },
    },
  },
  {
    path: '/heating/:siteId',
    name: 'heating',
    component: () => import('@/views/heating/HeatingView.vue'),
    meta: {
      title: 'Chauffage',
    },
  },
  {
    path: '/campaigns/:siteId',
    name: 'campaign',
    component: () => import('@/views/Campaigns/CampaignsView.vue'),
    props: true,
    redirect: { name: 'campaigns.draft' },
    meta: { acl: { dashboard: true } },
    children: [
      {
        path: 'draft',
        name: 'campaigns.draft',
        components: {
          pages: () => import('@/components/campaigns/Draft.vue'),
        },
        meta: { title: 'Campagne en préparation' },
      },
      {
        path: 'started',
        name: 'campaigns.started',
        components: {
          pages: () => import('@/components/campaigns/Started.vue'),
        },
        meta: { title: 'Campagne en cours' },
      },
      {
        path: 'closed',
        name: 'campaigns.closed',
        components: {
          pages: () => import('@/components/campaigns/History.vue'),
        },
        meta: { title: 'Campagnes réalisées' },
      },
    ],
  },
  {
    path: '/campaigns/:site/:campaignId/report/:reportId/',
    name: 'campaigns.report',
    component: () => import('@/views/Campaigns/CampaignReport.vue'),
    props: true,
    meta: {
      title: 'Rapport de campagne',
      external: true,
      header: false,
      footer: false,
      navigation: false,
    },
  },
  {
    path: '/ecotips/:siteId',
    name: 'ecotips',
    component: () => import('@/views/Ecotips/EcotipsView.vue'),
    props: true,
    redirect: { name: 'ecotips.categories' },
    meta: { mvp: true },
    children: [
      {
        path: 'categories',
        name: 'ecotips.categories',
        components: {
          pages: () => import('@/views/Ecotips/CategoriesView.vue'),
        },
        props: true,
        meta: {
          title: 'Catégories écogestes',
          mvp: true,
        },
      },
      {
        path: 'categories/:category',
        name: 'ecotips.subcategories',
        components: {
          pages: () => import('@/views/Ecotips/SubCategories.vue'),
        },
        props: true,
        meta: {
          title: 'Sous-catégories écogestes',
          mvp: true,
        },
      },
      {
        path: 'categories/:category/:subcategory',
        name: 'ecotips.list',
        components: {
          pages: () => import('@/views/Ecotips/EcotipsList.vue'),
        },
        redirect: { name: 'ecotips.list.todo' },
        props: true,
        meta: {
          mvp: true,
        },
        children: [
          {
            path: 'tips/:sort?',
            name: 'ecotips.list.todo',
            components: {
              pages: () => import('@/components/ecotips/Todo.vue'),
            },
            props: true,
            meta: {
              title: 'Écogestes à mettre en place',
              mvp: true,
            },
          },
          {
            path: 'habits/:sort?',
            name: 'ecotips.list.habits',
            components: {
              pages: () => import('@/components/ecotips/HabitsOn.vue'),
            },
            props: true,
            meta: {
              title: 'Écogestes acquis',
              mvp: true,
            },
          },
          {
            path: 'archived/:sort?',
            name: 'ecotips.list.archived',
            components: {
              pages: () => import('@/components/ecotips/Archived.vue'),
            },
            props: true,
            meta: {
              title: 'Écogestes archivés',
              mvp: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/rpu/:siteId',
    name: 'rpu',
    component: () => import('@/views/RpuView.vue'),
    props: true,
    meta: { title: 'RPU' },
  },
  {
    path: '/faq/:siteId',
    name: 'faq',
    component: () => import('@/views/Help/FAQView.vue'),
    props: true,
    meta: { title: 'FAQ' },
  },
  {
    path: '/notifications/:siteId',
    name: 'notifications',
    component: () => import('@/views/NotificationsView.vue'),
    meta: { title: 'Notifications' },
  },
  {
    path: '/tools/report/:report_id',
    name: 'tools.report',
    component: () => import('@/components/layout/Report/MonthlyReport.vue'),
    props: true,
    meta: { title: 'Bilan énergie' },
  },
  {
    path: '/tools/report/shared/:uuid',
    name: 'tools.report.shared',
    component: () => import('@/components/layout/Report/MonthlyReport.vue'),
    props: true,
    meta: {
      title: 'Bilan énergie',
      external: true,
      header: false,
      footer: false,
      navigation: false,
    },
  },
  {
    path: '/mission/:siteId',
    name: 'mission',
    component: () => import('@/views/Missions/MissionsView.vue'),
    props: true,
    redirect: { name: 'mission.summary' },
    meta: { title: 'Objectifs d’économies' },
    children: [
      {
        path: 'summary',
        name: 'mission.summary',
        components: {
          energyView: () => import('@/views/Missions/NightWatt/NightWattSummary.vue'),
        },
      },
      {
        path: 'preparation',
        name: 'mission.preparation',
        components: {
          energyView: () => import('@/views/Missions/MissionPreparationView.vue'),
        },
      },
      {
        path: 'action',
        name: 'mission.action',
        components: {
          energyView: () => import('@/views/Missions/MissionActionView.vue'),
        },
      },
    ],
  },
  {
    path: '/tools/quiz/:uuid',
    name: 'tools.quiz',
    component: () => import('@/views/Quiz/QuizView.vue'),
    props: true,
    meta: {
      title: 'Quiz',
    },
  },
  {
    path: '/tools/quiz-end/:uuid',
    name: 'tools.quiz.end',
    component: () => import('@/views/Quiz/QuizEnd.vue'),
    meta: {
      title: 'Quiz',
    },
  },
  {
    path: '/tools/my-quiz/',
    name: 'tools.quiz.list',
    component: () => import('@/views/Quiz/QuizList.vue'),
    meta: {
      title: 'Mes Quiz',
    },
  },
  {
    path: '/doc',
    name: 'doc',
    component: () => import('@/views/Doc/DocView.vue'),
    children: [
      {
        path: '/doc/colors',
        name: 'doc.colors',
        component: () => import('@/views/Doc/ColorsView.vue'),
      },
      {
        path: '/doc/icons',
        name: 'doc.icons',
        component: () => import('@/views/Doc/IconsView.vue'),
      },
    ],
  },
  {
    path: '/health-check',
    name: 'health-check',
    component: () => import('@/views/HealthCheckView.vue'),
    props: true,
    meta: {
      title: 'Health-Check',
    },
  },
  {
    path: '/site/:siteId',
    name: 'site',
    component: () => import('@/views/Init/SiteView.vue'),
    props: true,
    meta: {
      title: 'Site',
      dashboard: false,
    },
  },
  {
    path: '/site',
    name: 'site.new',
    component: () => import('@/views/Init/SiteView.vue'),
    props: true,
    meta: {
      title: 'Création site',
      dashboard: false,
    },
  },
  {
    path: '/parameters/:siteId',
    name: 'parameters',
    component: () => import('@/views/Settings/ParametersView.vue'),
    props: true,
    redirect: { name: 'parameters.site' },
    children: [
      {
        path: 'site',
        name: 'parameters.site',
        component: () => import('@/views/Settings/Site/SiteView.vue'),
        meta: {
          title: 'Site',
          dashboard: true,
        },
      },
      {
        path: 'coordinates',
        name: 'parameters.site.coordinates',
        component: () => import('@/views/Settings/Site/Site/SiteEditView.vue'),
        meta: {
          title: 'Coordonnées',
          dashboard: true,
        },
      },
      {
        path: 'contract/:contractId',
        name: 'parameters.contract',
        component: () => import('@/views/Settings/Site/Contracts/ContractEditView.vue'),
        props: true,
        meta: {
          title: 'Contrat',
          acl: { contract: true },
        },
      },
      {
        path: 'contract',
        name: 'parameters.new_contract',
        component: () => import('@/views/Settings/Site/Contracts/ContractCreateView.vue'),
        props: true,
        meta: {
          title: 'Nouveau contrat',
          acl: { contract: true },
        },
      },
      {
        path: 'schedules',
        name: 'parameters.site.schedules',
        component: () => import('@/views/Settings/Site/Schedules/SchedulesEditView.vue'),
        meta: {
          title: "Périodes d'activité",
        },
      },
      {
        path: 'exceptional-days',
        name: 'parameters.site.exceptional_days',
        component: () => import('@/views/Settings/Site/ExceptionalDays/ExceptionalDaysEditView.vue'),
        meta: {
          title: 'Journées exceptionnelles',
        },
      },
      {
        path: 'members',
        name: 'parameters.members',
        component: () => import('@/views/Settings/MembersView.vue'),
        meta: { title: 'Membres' },
      },
      {
        path: 'equipments',
        name: 'parameters.equipments',
        component: () => import('@/views/Settings/EquipmentsView.vue'),
        meta: { title: 'Appareils' },
      },
      {
        path: 'devices',
        name: 'parameters.material',
        component: () => import('@/views/Settings/MaterialView.vue'),
        meta: { title: 'Capteurs' },
      },
      {
        path: 'alerts',
        name: 'parameters.alerts',
        component: () => import('@/views/Settings/AlertsView.vue'),
        meta: { title: 'Alertes' },
      },
    ],
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile/ProfileView.vue'),
    redirect: { name: 'profile-user' },
    children: [
      {
        path: 'profile',
        name: 'profile-user',
        components: {
          pages: () => import('@/views/Profile/UserView.vue'),
        },
        meta: { title: 'Profil' },
      },
      {
        path: 'password',
        name: 'profile-password',
        components: {
          pages: () => import('@/views/Profile/PasswordView.vue'),
        },
        meta: { title: 'Modifier mot de passe' },
      },
    ],
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    props: true,
    meta: {
      common: true,
      guest: true,
      header: false,
      navigation: false,
      footer: false,
    },
  },
  {
    path: '/oauth/exchange',
    name: 'oauth-exchange',
    component: () => import('@/views/OAuthExchangeView.vue'),
    meta: {
      common: true,
      header: false,
      navigation: false,
      footer: false,
    },
  },
  {
    path: '/cumulative/consumption/:siteId/:sourceId',
    name: 'cumulative.consumption',
    component: () => import('@/views/CumulativeConsumption.vue'),
    meta: {
      title: 'Définition des objectifs',
      acl: { dashboard: true },
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: support ? 'support' : 'dashboard' },
  },
  {
    path: '/pg/:id([A-F0-9]{12})',
    redirect: (to) => `/settings/gateways/pairing/${to.params.id}`,
  },
]

routes = supportRoutes.concat(routes)
routes = allowShortcuts(routes, ['siteId', 'sourceId'])

export default routes
