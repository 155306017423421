/* eslint-disable no-undef */
export default (field: string, defaultValue: unknown = undefined) => {
  if (!field) {
    return typeof require === 'undefined' ? import.meta.env : process.env
  }
  let value = typeof require === 'undefined' ? import.meta.env[field] : process.env[field]
  if (value !== undefined && value !== '') {
    // Process true/false values as real booleans
    if (['true', 'false'].includes(`${value}`.toLowerCase())) {
      value = `${value}`.toLowerCase() === 'true'
    }
    return value
  }
  return defaultValue
}
