import { production, support } from '@/helpers/env'

export const aclModule = {
  state: {
    role: null,
    features: [],
    programs: [],
    groups: [],
    isImpersonated: false,
    acl: {},
  },

  getters: {
    admin: (state) => state.role === 'SUPPORT_ROLE_NAME',
    canImpersonate: (state) =>
      state.role === 'SUPPORT_ROLE_NAME' ||
      state.role === 'ASSOCIATION_ROLE_NAME' ||
      state.role === 'PROGRAM_MANAGER_ROLE_NAME' ||
      state.isImpersonated ||
      state.role === 'PARTNER_ACCESS_ROLE_NAME',
    role: (state) => state.role,
    acl: (state, getters, rootState, rootGetters) => {
      const acl = {
        ...state.acl,
        ...state.groups.concat(rootGetters['impersonate/groups']).reduce((v, t) => {
          return {
            ...v,
            [t]: true,
          }
        }, {}),
        ...state.programs.concat(rootGetters['impersonate/programs']).reduce((v, t) => {
          return {
            ...v,
            [t]: true,
          }
        }, {}),
      }
      acl.impersonate = rootGetters['acl/canImpersonate']
      acl.support = rootGetters['acl/canImpersonate']
      acl.subscription = rootGetters['user/isUdwi']
      acl.metropole = rootGetters['user/isMetropole']
      acl.dashboard = rootGetters['user/isSetup']
      acl.comfort = rootGetters['sites/allSources'].some((s) => s.isFludia() || s.isWebdyn() || s.isComfort())
      acl.admin = rootGetters['acl/admin']
      if (support && acl.support) {
        for (const p in acl) {
          acl[p] = true
        }
      }
      acl.contract = rootGetters['sources/sources'].some((e) => e.isElectricity())

      // permet de conditionner l'affichage par rapport au groupe "developers" en production
      acl.developers =
        production &&
        (rootGetters['user/groups'].includes('developers') || rootGetters['impersonate/groups'].includes('developers'))
      return acl
    },
    can(state, getters, rootState, rootGetters) {
      return (object) => {
        if (typeof object === 'string') {
          object = { [object]: true }
        }
        const acl = rootGetters['acl/acl']
        for (const permission in object) {
          if (acl[permission] !== object[permission]) {
            return false
          }
        }
        return true
      }
    },
  },

  mutations: {
    setFeatures(state, features) {
      state.features = features
    },
    setRole(state, role) {
      state.role = role
    },
    setPrograms(state, programs) {
      state.programs = programs
    },
    setGroups(state, groups) {
      state.groups = groups
    },
    setImpersonator(state, user) {
      state.isImpersonated = !!user
    },
  },

  actions: {
    setACL({ commit }, { role, features, programs, groups }) {
      commit('setPrograms', programs)
      commit('setGroups', groups)
      commit(
        'setFeatures',
        features.filter((f) => f.granted).map((f) => f.name),
      )
      commit('setRole', role)
    },
    setImpersonator({ commit }, user) {
      commit('setImpersonator', user)
    },
    clear({ commit }) {
      commit('setPrograms', [])
      commit('setGroups', [])
      commit('setFeatures', [])
      commit('setRole', null)
      commit('setImpersonator', null)
    },
  },

  namespaced: true,
}
