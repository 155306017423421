import { RouteRecordRaw } from 'vue-router'

export const supportRoutes: RouteRecordRaw[] = [
  {
    path: '/support',
    name: 'support',
    redirect: { name: 'support.installations' },
    meta: { acl: { support: true } },
  },
  {
    path: '/support/installations',
    name: 'support.installations',
    component: () => import('@/support/views/Installations/InstallationsView.vue'),
    meta: { acl: { support: true } },
  },
  {
    path: '/support/users/:userId/sites/:siteid/',
    name: 'support.users.site',
    redirect: { name: 'support.users.site.details' },
    component: () => import('@/support/views/Users/Sites/SiteView.vue'),
    meta: {
      acl: { support: true },
    },
    children: [
      {
        path: 'details',
        name: 'support.users.site.details',
        component: () => import('@/support/views/Users/Sites/SiteViewDetails.vue'),
        meta: {
          acl: { support: true },
        },
      },
      {
        path: 'devices',
        name: 'support.users.site.devices',
        component: () => import('@/support/views/Users/Sites/SiteViewDevices.vue'),
        meta: {
          acl: { support: true },
        },
      },
      {
        path: 'consumption-profile',
        name: 'support.users.site.consumption_profile',
        component: () => import('@/support/views/Users/Sites/SiteViewConsumptionProfile.vue'),
        meta: {
          acl: { support: true },
        },
      },
    ],
  },
  {
    path: '/support/gateways',
    name: 'support.gateways',
    component: () => import('@/support/views/GatewaysView.vue'),
    meta: { acl: { support: true } },
  },
  {
    path: '/support/sensors',
    name: 'support.sensors',
    component: () => import('@/support/views/SensorsView.vue'),
    meta: { acl: { support: true } },
  },
  {
    path: '/support/map',
    name: 'support.map',
    component: () => import('@/support/views/MapView.vue'),
    meta: { acl: { support: true } },
  },
  {
    path: '/support/search',
    name: 'support.search_devices',
    component: () => import('@/support/views/SearchDevices.vue'),
    meta: { acl: { support: true } },
  },
  {
    path: '/support/energy-appointments',
    name: 'support.energy_appointments',
    component: () => import('@/support/views/EnergyAppointmentsView.vue'),
    meta: { acl: { support: true } },
  },
]
