import { debug, env, local } from '@/helpers/env'

export const config = {
  environment: env('VUE_APP_ENV', 'development'),
  debug,
  hippo: env('VUE_APP_SERVICE_BASE_URL', window.location.protocol + '//' + window.location.hostname + '/v1'),
  opossum: env('VUE_APP_AUTH_BASE_URL', window.location.protocol + '//' + window.location.hostname + ':8000'),
  // pour le local ou prod tant qu'on a pas d'url en support.udwi
  root: window.location.protocol + '//' + window.location.host.replace('support.', ''),
  wifi: env('VUE_APP_WIFI_WEBAPP_URL'),
  wordpress: env('VUE_APP_WORDPRESS_URL'),
  local,
}
