import { events } from '../utils/events'

/**
 * Show toasts outside any components by default bottom left of screen. see #UToast for options
 * if toast.relative is set, will place toast at toast.relative position (can be offset by x and y)
 * if toast.event is set, toast.relative is set to event.currentTarget
 * @param {*} toast
 */
export function toast(toast) {
  toast = Object.assign(
    {
      type: 'notice',
    },
    toast,
  )

  if (toast.event) {
    toast.relative = toast.event.currentTarget
    delete toast.event
  }
  if (toast.relative) {
    const offset = toast.relative.getBoundingClientRect()
    toast.x = offset.left + (toast.x ?? 0)
    toast.y = offset.top + (toast.y ?? 0)
    delete toast.relative
  }
  events.emit('toast', toast)
}
