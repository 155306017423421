import env from '@/env'

export { default as env } from '@/env'
/**
 * Debug mode
 */
export const debug: boolean = !!(env('VUE_APP_DEBUG') || localStorage.debug)
export const local: boolean = env('VUE_APP_ENV') === 'development'
export const support: boolean = window.location.href.includes('/support') || window.location.href.includes('support.')
export const production: boolean = env('VUE_APP_ENV') === 'production'
