/**
 * Convert array to object key indexed
 * @param {*} key key to index with
 * @param {*} array original array
 * @param {*} map optional map function to use as new value.
 * @returns
 */

export function keyBy(
  key: (value: string) => string,
  array: unknown[],
  map: ((value: boolean) => boolean) | null = null,
) {
  if (typeof key === 'function') {
    return array.reduce((all: Record<string, unknown>, value) => {
      all[key(value as string)] = map ? map(value as boolean) : value
      return all
    }, {})
  }
  return array.reduce((all: Record<string, unknown>, value) => {
    all[(value as string)[key]] = map ? map(value as boolean) : value
    return all
  }, {})
}
/**
 * Convert array to object key indexed array.
 * @param {*} key key to index with
 * @param {*} array original array
 * @param {*} map optional map function to use as new value.
 * @returns
 */
export function groupBy(
  key: (value: string) => string,
  array: unknown[],
  map: ((value: boolean) => boolean) | null = null,
) {
  if (typeof key === 'function') {
    return array.reduce((all: Record<string, unknown>, value) => {
      const k = key(value as string)
      if (!all[k]) {
        all[k] = []
      }
      ;(all[k] as unknown[]).push(map ? map(value as boolean) : value)
      return all
    }, {})
  }
  return array.reduce((all: Record<string, unknown>, value) => {
    if (!all[(value as string)[key]]) {
      all[(value as string)[key]] = []
    }
    ;(all[(value as string)[key]] as unknown[]).push(map ? map(value as boolean) : value)
    return all
  }, {})
}
/**
 * Removes duplicates values in an array indexed by key
 * @param {string} key Key used to deduplicates
 * @param {array} array Array to filter
 * @returns
 */
export function unique(key: { (value: string): string } | string, array: unknown[]) {
  if (!array && Array.isArray(key)) {
    return [...new Set(key)]
  }
  const object = keyBy(key as (value: string) => string, array)
  // keep order
  const uniques = Object.values(object)
  return array.filter((object) => uniques.includes(object))
}
/**
 * use `arr.at(-1)` instead
 * @deprecated
 * @param {array} arr
 * @returns
 */
export function last(arr: unknown[]) {
  return arr[arr.length - 1]
}
